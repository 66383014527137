import rise from '../../assets/home/2.jpg'; 
import ourfuture from '../../assets/home/ourfuture.jpg'; 
import teamMember3 from '../../assets/home/lynn.jpg'; 
import teamMember4 from '../../assets/home/12.jpg'; 
import launch from '../../assets/home/launch.JPG';
import future from '../../assets/home/future.jpeg';

export const blogs = [
  {
    id: 1,
    title: "The Rise of Kenyan Taste in the Culinary World",
    content: "Kenyan Taste Sauce by Mebiut is quickly becoming a household name in kitchens across the country. With its unique blend of locally sourced ingredients, it adds a distinctive flavor to every meal, capturing the essence of Kenyan cuisine. This sauce is not just a condiment; it represents a celebration of the rich culinary heritage of Kenya, bringing families together at the dinner table...",
    fullContent: "Kenyan Taste Sauce by Mebiut is quickly becoming a household name in kitchens across the country. With its unique blend of locally sourced ingredients, it adds a distinctive flavor to every meal, capturing the essence of Kenyan cuisine. Our sauce is crafted with passion and expertise, using traditional recipes that highlight the rich culinary heritage of Kenya. As we continue to innovate, we are committed to maintaining the highest standards of quality and sustainability. With Kenyan Taste Sauce, you can transform any dish into a delightful experience that reflects the vibrant culture of our land.",
    author: "Nelson Tommogo",
    date: "October 10, 2024",
    category: "Kenyan Cuisine",
    image: rise, 
    likes: 0,
  },
  {
    id: 2,
    title: "Why Sustainability Matters in Food Production",
    content: "At Mebiut, sustainability is at the heart of everything we do. Our Kenyan Taste Sauce and Mayonnaise are crafted with care, ensuring that we minimize our environmental impact while supporting local farmers. We understand that the food industry has a significant role to play in environmental conservation, which is why we prioritize eco-friendly practices throughout our production process. From sourcing ingredients to packaging, every step is designed to protect our planet...",
    fullContent: "At Mebiut, sustainability is at the heart of everything we do. Our Kenyan Taste Sauce and Mayonnaise are crafted with care, ensuring that we minimize our environmental impact while supporting local farmers. We believe that food production should not only be about great taste but also about preserving the planet for future generations. Our commitment to using locally sourced ingredients means we’re not only supporting the economy but also reducing carbon footprints associated with long-distance transportation. As we look towards the future, we aim to inspire others in the industry to adopt sustainable practices.",
    author: "Charles Makuka",
    date: "September 25, 2024",
    category: "Sustainability",
    image: teamMember3, 
    likes: 0,
  },
  {
    id: 3,
    title: "Mebiut Mayonnaise: A Staple for Every Kitchen",
    content: "Mebiut’s mayonnaise has rapidly gained popularity for its rich, creamy texture and flavor. Made with high-quality ingredients like fresh eggs, vegetable oil, and a hint of mustard, it’s the perfect companion for sandwiches, salads, and more. Our mayonnaise is versatile and can be used in countless recipes, from dressings to dips, enhancing the flavor of any dish with its smooth consistency and delightful taste...",
    fullContent: "Mebiut’s mayonnaise has rapidly gained popularity for its rich, creamy texture and flavor. Made with high-quality ingredients like fresh eggs, vegetable oil, and a hint of mustard, it’s the perfect companion for sandwiches, salads, and more. The versatility of our mayonnaise allows it to be used in various dishes, from classic coleslaw to gourmet dips. We take pride in our product's integrity, ensuring every jar is filled with only the best ingredients. Whether you’re a seasoned chef or a home cook, Mebiut’s mayonnaise is a must-have for enhancing your culinary creations.",
    author: "Esther Njenga",
    date: "August 30, 2024",
    category: "Condiments",
    image: teamMember4,
    likes: 0,
  },
  {
    id: 4,
    title: "Kenyan Taste Sauce: Enhancing Every Meal",
    content: "Kenyan Taste Sauce by Mebiut brings out the authentic flavors of local cuisine. Whether you’re preparing a simple meal or a feast, this sauce adds a burst of flavor that elevates any dish, making every bite unforgettable. It’s not just about enhancing taste; it’s about creating a connection to our roots and celebrating the vibrant food culture of Kenya. Each meal becomes a storytelling experience, filled with the rich flavors and traditions of our land...",
    fullContent: "Kenyan Taste Sauce by Mebiut brings out the authentic flavors of local cuisine. Whether you’re preparing a simple meal or a feast, this sauce adds a burst of flavor that elevates any dish, making every bite unforgettable. Our sauce pairs perfectly with grilled meats, vegetables, and even as a marinade. We carefully select our ingredients to ensure a balance of taste that truly represents Kenyan culinary traditions. Each bottle is a celebration of our culture, inviting everyone to enjoy the rich and diverse flavors that our country has to offer.",
    author: "Mebiut Team",
    date: "July 15, 2024",
    category: "Sauces and Condiments",
    image: rise, 
    likes: 0,
  },
  {
    id: 5,
    title: "Mebiut Officially Launches Website!",
    content: "We are thrilled to announce the launch of our new website, mebiut.com! Our platform is now live, offering customers a convenient way to explore our products, place orders, and learn more about Mebiut’s journey. The website features an intuitive design that allows users to navigate easily and find exactly what they need. From recipes to customer reviews, everything is designed to enhance your experience with Mebiut...",
    fullContent: "We are thrilled to announce the launch of our new website, mebiut.com! Our platform is now live, offering customers a convenient way to explore our products, place orders, and learn more about Mebiut’s journey. The website serves as a hub for everything Mebiut, bringing our sauces, like Kenyan Taste and Mayonnaise, closer to your kitchen. We’ve designed the site to be user-friendly and visually appealing, ensuring that your shopping experience is seamless. With just a few clicks, you can discover new recipes, special promotions, and the stories behind our products.",
    author: "Mebiut Team",
    date: "October 1, 2024",
    category: "Aloyce Otieno",
    image: future,
    likes: 0,
  },
  {
    id: 6,
    title: "The Official Launch of Mebiut: A New Taste in Town!",
    content: "We are excited to introduce Mebiut to the world! Our brand is all about bringing unforgettable flavors to your table with our Kenyan Taste Sauce and Mayonnaise. During our official launch event, we had the pleasure of sharing these unique flavors with our early customers, who couldn’t stop raving about the taste. The feedback was overwhelming, and it has motivated us to continue innovating and expanding our product line...",
    fullContent: "We are excited to introduce Mebiut to the world! Our brand is all about bringing unforgettable flavors to your table with our Kenyan Taste Sauce and Mayonnaise. During our official launch event, we had the pleasure of sharing these unique flavors with our early customers, who couldn’t stop raving about the taste. Mebiut is here to make every bite unforgettable, and we believe our products will become staples in kitchens everywhere. Our launch marks the beginning of a journey filled with culinary exploration and innovation as we continue to expand our range of sauces and condiments.",
    author: "Nelson Tommogo",
    date: "September 20, 2024",
    category: "Joshua Obondo",
    image: launch,
    likes: 0,
  },
  {
    id: 10,
    title: "The Future of Mebiut: Expanding Across Borders",
    content: "As Mebiut continues to grow, we’re looking at expanding the reach of our Kenyan Taste Sauce and Mayonnaise beyond Kenya. Our ambition is not only to introduce our unique flavors to new markets but also to build a brand that resonates with food lovers everywhere. We aim to create a community of culinary enthusiasts who appreciate quality and authenticity in their food choices...",
    fullContent: "As Mebiut continues to grow, we’re looking at expanding the reach of our Kenyan Taste Sauce and Mayonnaise beyond Kenya. With growing demand in neighboring countries, we’re excited about the future of our brand. Our vision is to introduce our products to a wider audience while maintaining the quality and essence of our brand. As we prepare for this expansion, we are focused on building partnerships and exploring new markets. We are committed to delivering the authentic taste of Kenya, no matter where our customers are located.",
    author: "Mebiut Team",
    date: "January 22, 2024",
    category: "Business Expansion",
    image: ourfuture, 
    likes: 0,
  },
];

  
export default blogs;
