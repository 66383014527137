import product1 from '../../assets/home/3.jpg'; 
import product2 from '../../assets/home/2.jpg'; 
import product3 from '../../assets/home/6.jpeg'; 
import product4 from '../../assets/home/9.jpg';
import product5 from '../../assets/home/7.jpg';
import product6 from '../../assets/home/product6.jpeg'; 



const fakeData = [
    {
        id: 1,
        title: 'Kenyan Taste',
        img: product1,
        category: 'kenyan taste'
    },
    {
        id: 2,
        title: 'Spiced Mayonnaise',
        img: product6,
        category: 'mayonnaise'
    },
    {
        id: 3,
        title: 'Kenyan Taste Mayo',
        img: product2,
        category: 'kenyan taste'
    },
    {
        id: 4,
        title: 'Kenyan Taste',
        img: product3,
        category: 'kenyan taste'
    },
    {
        id: 5,
        title: 'Kenyan Taste',
        img: product4,
        category: 'kenyan taste'
    },
    {
        id: 6,
        title: 'Kenyan Taste',
        img: product5,
        category: 'kenyan taste'
    },
    
];

export default fakeData;
